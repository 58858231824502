import * as React from "react"
import {graphql } from "gatsby"


import Navbar from "../../components/navbar"
import Reformfooter from "../../components/footer"
// import Bookaclass from "../../components/bookaclass"
import Internalbanner from "../../components/internalbanner"
// import Sectiona from "../../homepage/sectiona"
// import Sectionb from "../../homepage/sectionb"
import Findus from "../../homepage/findus"
import Homeswiper from "../../homepage/homeswiper"
import Imagetrio from "../../components/imagetrio"
import Sidebar from "../../components/sidebar"
import Layout from "../../components/layout"
import Seo from "../../components/seo"
import Yogaback from "../../images/yoga4.jpg";
import YogaPic1 from "../../images/studio/barre-1.jpg"
import YogaPic2 from "../../images/studio/barre-2.jpg"
import YogaPic3 from "../../images/studio/barre-3.jpg"
import YogaPic4 from "../../images/studio/barre-4.jpg"

const Barre_burn = ({ data, location }) => {
  const siteTitle = data.site.siteMetadata?.title || `Barre Burn`


  return (
    <Layout location={location} title={siteTitle}>
    <Seo title="Barre Burn Pilates" description="Barre Burn Pilates classes at Reform Pilates Cork" />
    <Navbar />
    <Internalbanner title="Barre Burn" subtitle="A unique toning and cardio mix for anyone" bannerpic={YogaPic3}/>
    <section className="internal-page-main gencont">
    <div className="container">
    <div className="columns is-marginless">
    <div className="column is-8">
    <h3>Barre Burn</h3><hr/>
    <p class="toningtag">
    Perfect for: <span className="tag">building leaner limbs</span><span className="tag">defining waist</span><span className="tag">adding cardio to your routine</span>
    </p>
    <p class="blacksignature">An exciting addition to our class schedule is Barre Burn.</p>
    <p>If you’re looking for a new toning and cardio mix with specialist support, this class is particularly effective for changing your body shape as well as toning leg, arm, and 
    core muscles.</p> 
    <p>It helps to lengthen your body and, like all of our classes, improves posture and general fitness too.</p>
    <p>At <strong>Reform Pilates</strong>, we have taken the standard Barre class and added a selection of small equipment including Bosu balance trainers, bands, balls, dumbbells and, of course, a Barre. 
    A complete body workout, with a splash of graceful movements added in… yes, please! </p>

    <div className="columns is-marginless is-centered">
    <div className="column is-12 is-paddingless">
    <img src={YogaPic1} alt="Beginners Yoga Classes Cork" className="midyogapic"/>
    </div>
    </div>


    </div>

    <div className="column is-3 is-offset-1">
    <Sidebar />
    </div>
    </div>
    </div>
    </section>
    <Imagetrio imagea={YogaPic2} imageb={YogaPic3} imagec={YogaPic4} />
    <Findus />
    <Homeswiper />
    <Reformfooter />
    </Layout>
    )
}

export default Barre_burn

export const pageQuery = graphql`
query {
  site {
    siteMetadata {
      title
    }
  }
  allMarkdownRemark(sort: { fields: [frontmatter___date], order: DESC }) {
    nodes {
      excerpt
      fields {
        slug
      }
      frontmatter {
        date(formatString: "MMMM DD, YYYY")
        title
        description
      }
    }
  }
}
`
